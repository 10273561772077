import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

export default class extends Controller {
  connect() {
    this.datepicker = flatpickr(this.element, this.flatpickrConfig());
    this.element.nextElementSibling.addEventListener("input", this.formatInputValue);
  }

  flatpickrConfig() {
    const date = new Date();
    const maximumDate = new Date(date.getFullYear(), date.getMonth() + 0);

    return {
      mode: "multiple",
      maxDate: maximumDate, // Set maximum selection as current month
      allowInput: false,
      altInput: true,
      sort: true,
      defaultDate: this.element.value.split(" "),
      plugins: [
        new monthSelectPlugin({
          allowInput: false,
          //defaults to "F Y", ensure day is included so value does not need to be manipulated before cast
          dateFormat: "Y-m-d",
          altFormat: "M Y", //defaults to "F Y"
        }),
      ],
    };
  }
}
