import { Controller } from "@hotwired/stimulus";

// Controller for express submit reference review.
export default class extends Controller {
  connect() {
    this.additionalReferenceInput.addEventListener("change", (e) => {
      if (e.target.value === "true") {
        this.submitButton.dataset.turbo = true;
      } else {
        this.submitButton.dataset.turbo = false;
      }
    });
  }

  get additionalReferenceInput() {
    return this.element.querySelector("#additional_reference");
  }

  get submitButton() {
    return this.element.querySelector('input[type="submit"]');
  }
}
