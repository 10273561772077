import { Controller } from "@hotwired/stimulus";
import {
  templatedEvents,
  toggleScriptFormVisibility,
  toggleCallResultSelectVisibility,
  setupOutcomeDropdownChoiceManagement,
  filterTemplateDropdownList,
  toggleOutreachEventMessageBodyInput
} from "../../../../../frontend/javascript/utils/outreach_events";

export default class extends Controller {
  connect() {
    this.scriptFormContainer = this.element.querySelector(
      "#outreach_event_applicant_reference_report_script_form_container",
    );
    this.applicantScriptForm = this.element.querySelector("#outreach_applicant_script_form"); //?
    this.contactMethodDropdown = this.element.querySelector(
      "#outreach_event_applicant_reference_report_contact_method",
    );
    this.directionDropdown = this.element.querySelector("#outreach_event_applicant_reference_report_direction");
    this.templateDropdown = this.element.querySelector("#outreach_event_applicant_reference_report_template_used");
    this.outcomeOptionsDropdown = this.element.querySelector("#outreach_event_applicant_reference_report_outcome");
    this.referenceDropdown = this.element.querySelector("#reference-dropdown");
    this.contactResultDropdown = this.element.querySelector(
      "#outreach_event_applicant_reference_report_contact_result",
    );
    this.contactResultDropdownWrapper = this.element.querySelector(
      "#outreach_event_applicant_reference_report_contact_result_select_wrapper",
    );

    this.templatedCheckbox = this.element.querySelector("#outreach_event_applicant_reference_report_templated"); //?
    this.applicantEmailInput = this.element.querySelector("#applicant_reference_report_email");
    this.phoneInput = this.element.querySelector("#applicant_reference_report_phone");
    this.smsEnabledInput = this.element.querySelector("#applicant_reference_report_phone_sms_enabled");
    this.sendContactEmailCheckbox = this.element.querySelector("#send_applicant_initial_outreach");

    toggleScriptFormVisibility(this.scriptFormContainer, this.contactMethodDropdown);
    toggleCallResultSelectVisibility(
      this.contactResultDropdown,
      this.contactResultDropdownWrapper,
      this.contactMethodDropdown,
    );
    this.toggleSendApplicantInitialOutreach();
    setupOutcomeDropdownChoiceManagement(
      this.outcomeOptionsDropdown,
      this.contactMethodDropdown,
      this.directionDropdown,
      this.contactResultDropdown,
    );
    this.setupTemplatedEventsExperience();
  }

  setupTemplatedEventsExperience() {
    const getRenderedMessageIds = () => {
      let element = document.getElementById("rendered-message-ids");
      let renderedMessageIds = JSON.parse(element.getAttribute("data-rendered-message-ids"));
      return renderedMessageIds;
    };

    const getTemplateMetadata = () => {
      let element = document.getElementById("message-template-metadata");
      let templateMetadata = JSON.parse(element.getAttribute("data-message-template-metadata"));
      return templateMetadata;
    };

    const toggleReferencePickerBasedOnTemplate = () => {
      let templateMetadata = getTemplateMetadata();
      if (
        templateMetadata[this.templateDropdown.value] &&
        templateMetadata[this.templateDropdown.value].requires_reference
      ) {
        document.getElementById("reference-picker").hidden = false;
      } else {
        document.getElementById("reference-picker").hidden = true;
      }
    };

    const toggleTemplatedEventsExperience = () => {
      if (templatedEvents().includes(this.contactMethodDropdown.value) && this.directionDropdown.value === "OUTBOUND") {
        document.getElementById("templated-events-experience").hidden = false;
        this.templateDropdown.required = true;
      } else {
        document.getElementById("templated-events-experience").hidden = true;
        this.templateDropdown.required = false;
        this.templateDropdown.value = null;
      }
    };

    const showCorrectMessageTemplate = () => {
      let renderedMessageIds = getRenderedMessageIds();
      let idToShow = this.contactMethodDropdown.value?.toLowerCase() + "_" + this.templateDropdown.value + "_";
      if (this.referenceDropdown) {
        idToShow = idToShow + this.referenceDropdown.value;
      }
      for (let i = 0; i < renderedMessageIds.length; i++) {
        if (renderedMessageIds[i] === idToShow) {
          document.getElementById(renderedMessageIds[i]).hidden = false;
        } else {
          document.getElementById(renderedMessageIds[i]).hidden = true;
        }
      }
    };

    const setupMessageBodyInput = () => {
      let messageBodyInputDiv = document.getElementById('message-body-input-div');
      let messageBodyInput = document.getElementById('outreach_event_applicant_reference_report_message_body');
      let templateMetadata = getTemplateMetadata();
      toggleOutreachEventMessageBodyInput(messageBodyInputDiv, messageBodyInput, templateMetadata, this.templateDropdown)
    }

    const setupTemplateDropdownList = () => {
      let templateMetdata = getTemplateMetadata();
      filterTemplateDropdownList(templateMetdata, this.contactMethodDropdown, this.templateDropdown)
    }

    const selectCorrectOutcomeBasedOnTemplateUsed = () => {
      let templateMetdata = getTemplateMetadata();
      if (
        templateMetdata[this.templateDropdown.value] &&
        templateMetdata[this.templateDropdown.value].expected_outcome
      ) {
        this.outcomeOptionsDropdown.value = templateMetdata[this.templateDropdown.value].expected_outcome;
      }
    };

    this.templateDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleReferencePickerBasedOnTemplate();
      selectCorrectOutcomeBasedOnTemplateUsed();
      setupMessageBodyInput();
      setupTemplateDropdownList();
    });
    if (this.referenceDropdown) {
      this.referenceDropdown.addEventListener("change", (e) => {
        showCorrectMessageTemplate();
      });
    }
    this.contactMethodDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
      setupMessageBodyInput();
      setupTemplateDropdownList();
    });
    this.directionDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    showCorrectMessageTemplate();
    toggleReferencePickerBasedOnTemplate();
    toggleTemplatedEventsExperience();
    selectCorrectOutcomeBasedOnTemplateUsed();
    setupMessageBodyInput();
    setupTemplateDropdownList();
  }

  toggleSendApplicantInitialOutreach() {
    const toggleSendInitialOutreach = () => {
      let email = document.getElementById("applicant_reference_report_email").value;
      let phoneNumber = document.getElementById("applicant_reference_report_phone").value;
      let smsEnabled = document.getElementById("applicant_reference_report_phone_sms_enabled").checked;
      if (this.sendContactEmailCheckbox) {
        this.sendContactEmailCheckbox.checked = false;
        this.sendContactEmailCheckbox.disabled = !(email || (phoneNumber && smsEnabled));
      }
    };
    this.applicantEmailInput?.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    });
    this.phoneInput?.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    });
    this.smsEnabledInput?.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    });
    this.contactMethodDropdown?.addEventListener("change", (_e) => {
      toggleSendInitialOutreach();
    });
  }
}
