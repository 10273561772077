export function templatedEvents() {
  return ["EMAIL", "TEXT"];
}

export function toggleScriptFormVisibility(scriptFormContainer, contactMethodDropdown) {
  if (!scriptFormContainer) return;

  const toggleVisibility = () => (scriptFormContainer.hidden = contactMethodDropdown.value !== "CALL");
  contactMethodDropdown.addEventListener("change", toggleVisibility);
  // Set initial visibility
  toggleVisibility();
}

export function setupOutcomeDropdownChoiceManagement(
  outcomeOptionsDropdown,
  contactMethodDropdown,
  directionDropdown,
  contactResultDropdown,
) {
  const outcomesOptions = JSON.parse(outcomeOptionsDropdown.getAttribute("data-outcomes"));

  const updateOutcomeDropdown = () => {
    const optionValues = determineOutcomeOptions(
      outcomesOptions,
      contactMethodDropdown,
      directionDropdown,
      contactResultDropdown,
    );
    reorderDropdownOptions(optionValues, outcomeOptionsDropdown);
  };

  contactResultDropdown.addEventListener("change", updateOutcomeDropdown);
  contactMethodDropdown.addEventListener("change", updateOutcomeDropdown);
  directionDropdown.addEventListener("change", updateOutcomeDropdown);

  // Initialize the dropdown options
  updateOutcomeDropdown();
}

export function toggleOutreachEventMessageBodyInput(messageBodyInputDiv, messageBodyInput, templateMetadata, templateDropdown) {
  if (templateMetadata[templateDropdown.value] &&
    templateMetadata[templateDropdown.value].allows_message_body_input) {
    messageBodyInputDiv.hidden = false;
    messageBodyInput.required = true;
  } else {
    messageBodyInputDiv.hidden = true;
    messageBodyInput.required = false;
  }
}

export function filterTemplateDropdownList(templateMetdata, contactMethodDropdown, templateDropdown) {
  Array.from(templateDropdown.options).forEach((option) => {
    if (templateMetdata[option.value] && templateMetdata[option.value].contact_methods &&
      templateMetdata[option.value].contact_methods.includes(contactMethodDropdown.value)) {
      option.hidden = false;
    } else {
      option.hidden = true;
      if (option.value === templateDropdown.value) {
        templateDropdown.value = null;
      }
    }
  });
}

export function toggleCallResultSelectVisibility(
  contactResultDropdown,
  contactResultDropdownWrapper,
  contactMethodDropdown,
) {
  if (!contactResultDropdown || !contactResultDropdownWrapper) return;

  const toggleVisibility = (value) => {
    if (value == "CALL") {
      contactResultDropdownWrapper.hidden = false;
      contactResultDropdown.required = true;
    } else {
      contactResultDropdownWrapper.hidden = true;
      contactResultDropdown.selectedIndex = 0;
      contactResultDropdown.required = false;
    }
  };

  toggleVisibility(contactMethodDropdown.value);
  contactMethodDropdown.addEventListener("change", (e) => {
    toggleVisibility(e.target.value);
  });
}

function determineOutcomeOptions(outcomes, contactMethodDropdown, directionDropdown, contactResultDropdown) {
  // Return empty array unless contact method and direction value are in outcome options
  if (!(contactMethodDropdown.value in outcomes && directionDropdown.value in outcomes[contactMethodDropdown.value])) {
    return [];
  }

  let optionsObj = {};

  if (contactResultDropdown.value in outcomes[contactMethodDropdown.value][directionDropdown.value]) {
    optionsObj = outcomes[contactMethodDropdown.value][directionDropdown.value][contactResultDropdown.value];
  } else {
    optionsObj = outcomes[contactMethodDropdown.value][directionDropdown.value]["DEFAULT"];
  }

  return Object.keys(optionsObj);
}

function reorderDropdownOptions(optionValues, dropdown) {
  const optionsArray = Array.from(dropdown.options);
  // Create an order map from the array
  const orderMap = {};
  optionValues.forEach((value, index) => {
    orderMap[value] = index;
  });

  // Sort the options based on desired order
  optionsArray.sort((a, b) => {
    const orderA = orderMap.hasOwnProperty(a.value) ? orderMap[a.value] : Infinity;
    const orderB = orderMap.hasOwnProperty(b.value) ? orderMap[b.value] : Infinity;
    return orderA - orderB;
  });

  // Update the select element with sorted options
  optionsArray.forEach((option) => {
    // Show or hide the option
    option.hidden = !orderMap.hasOwnProperty(option.value);
    // Append option back to the select element to reorder
    dropdown.appendChild(option);
  });

  // Reset the selected value
  dropdown.value = "";
}
