import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    this.datepicker = flatpickr(this.element, this.flatpickrConfig());
    this.element.addEventListener("input", this.formatInputValue);
  }

  flatpickrConfig() {
    return {
      allowInput: true, // Allow users to enter input without using datepicker
      altInput: true, // Enable alternate display input
      altFormat: "m/d/Y h:i K", // Display date in more user friendly MM/DD/YYYY hh::mm am/pm format
      enableTime: true,
    };
  }

  // Prevent letter input to prevent people from attempting to parse dates like "January 1, 1995".
  // Does not parse correctly with the different alt/date format.
  formatInputValue(e) {
    e.target.value = e.target.value.replace(/[^0-9\-./]/g, "");
  }
}
