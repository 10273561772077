import { Controller } from "@hotwired/stimulus";

// Prevent hidden fields from preventing submission by dynamically requiring input.
// Iterates through each input in a fieldset and sets the required attribute by an initialized data-attribute and the
// parent fieldset's current visibility.
export default class extends Controller {
  connect() {
    const fieldsets = this.element.querySelectorAll("fieldset");

    fieldsets.forEach((elemGroup) => {
      for (const elem of elemGroup.children) {
        const input = getInputElement(elem);
        if (input === null) {
          continue;
        }
        if (input.hasAttribute("required")) {
          // Save initial state of required attribute as data-attribute on each element in each group
          input.setAttribute("data-required", true);

          // Remove required attribute on already hidden fields when initialized
          changeInputAttributes(elemGroup);
        }
      }

      // Observe each fieldset for attribute changes and change field required attributes depending on visibility
      const observer = createObserver(elemGroup);
      observer.observe(elemGroup, {
        attributes: true,
      });
    });

    function createObserver(observedElement) {
      return new MutationObserver((mutations) => {
        mutations.forEach(function (mutation) {
          if (mutation.type !== "attributes") return;

          changeInputAttributes(mutation.target, observedElement);
        });
      });
    }

    // If fieldset is hidden, remove required attribute from hidden fields. Set required attribute back when fields are unhidden.
    function changeInputAttributes(observedElement) {
      for (const elem of observedElement.children) {
        const input = getInputElement(elem);
        if (input === null) {
          continue;
        }
        if (observedElement.hidden) {
          if (input.hasAttribute("required")) {
            input.removeAttribute("required");
          }
          input.setAttribute("disabled", "disabled");
        } else {
          if (input.dataset.required === "true") {
            input.setAttribute("required", "true");
          }
          input.removeAttribute("disabled");
        }
      }
    }

    function getInputElement(element) {
      return element.querySelector("input, select, textarea");
    }
  }
}
