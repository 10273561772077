import * as bootstrap from "bootstrap";

document.addEventListener("DOMContentLoaded", function () {
  setupTooltips();
  //formValidation();
});

export function setupTooltips() {
  var tooltips = Array.from(document.querySelectorAll('[data-toggle="tooltip"]'));

  tooltips.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
}

// Allows for client side validation
// Currently disabled until I can be sure our beta testers (clients) won't have unexpected issues
function formValidation() {
  const forms = document.querySelectorAll(".requires-validation");

  function validateForm(form) {
    form.addEventListener(
      "submit",
      function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add("was-validated");
      },
      false,
    );
  }

  Array.from(forms).forEach(validateForm);
}
