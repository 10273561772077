import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.formatInputListener);
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.formatInputListener);
  }

  // Prevent exponential's and other characters from being inputted as value
  formatInputListener(e) {
    const invalidChars = ["-", "e", "+", "E"];

    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }
}
