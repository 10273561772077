import { Controller } from "@hotwired/stimulus";
import { showBlock, hide } from '../../../utils';
import { setupTooltips } from "../../../bootstrap";

// Reference Lease Duration Form controller
export default class extends Controller {

  connect() {
    this.datesNotKnown = this.element.querySelector('#dates_not_known');
    this.monthsNotKnown = this.element.querySelector('#months_not_known');
    this.residentDurationDiv = this.element.querySelector('#resident-duration-div');
    this.residentDurationInput = this.element.querySelector('#reference_residence_duration_months');
    this.residentDurationRequiredSymbol = this.element.querySelector('#resident-duration-required-symbol');

    this.moveInDateRequiredSymbol = this.element.querySelector('#move-in-required-symbol');
    this.moveOutDateRequiredSymbol = this.element.querySelector('#move-out-required-symbol');
    this.moveInDateInput = this.element.querySelector('#reference_applicant_move_in_date');
    this.moveOutDateInput = this.element.querySelector('#reference_applicant_move_out_date');
    this.moveDateDiv = this.element.querySelector('#move-date-div');

    let storedDatesNotKnown = sessionStorage.getItem('dates_not_known_checked');
    if (storedDatesNotKnown && this.datesNotKnown) {
      this.datesNotKnown.checked = JSON.parse(storedDatesNotKnown);
    }
    let storedMonthsNotKnown = sessionStorage.getItem('months_not_known_checked');
    if (storedMonthsNotKnown && this.monthsNotKnown) {
      this.monthsNotKnown.checked = JSON.parse(storedMonthsNotKnown);
    }

    this.datesNotKnown?.addEventListener("change", this.datesNotKnownEventListener.bind(this));
    this.monthsNotKnown?.addEventListener("change", this.monthsNotKnowEventListener.bind(this));
    this.residentDurationInput?.addEventListener("input", this.residentDurationEventListener.bind(this));
    this.syncFormWithDatesNotKnown();
    this.syncFormWithMonthsNotKnown();
    this.syncFormWithResidentDuration();
    setupTooltips();
  }

  datesNotKnownEventListener(e) {
    this.syncFormWithDatesNotKnown();
  }

  monthsNotKnowEventListener(e) {
    this.syncFormWithMonthsNotKnown();
  }

  residentDurationEventListener(e) {
    this.syncFormWithResidentDuration();
  }

  syncFormWithDatesNotKnown() {
    if (!this.datesNotKnown) return

    if (this.datesNotKnown.checked) {
      this.requireMoveDates(false);
      return
    }

    sessionStorage.setItem('dates_not_known_checked', this.datesNotKnown.checked);
    if (this.monthsNotKnown.checked || this.datesNotKnown.checked) {
      this.requireMoveDates(false);
    } else {
      this.requireMoveDates(true);
    }
  }

  syncFormWithMonthsNotKnown() {
    if (!this.monthsNotKnown) return

    sessionStorage.setItem('months_not_known_checked', this.monthsNotKnown.checked);
    if (this.monthsNotKnown.checked) {
      this.requireResidentDuration(false);
    } else {
      this.requireResidentDuration(true);
    }
  }

  syncFormWithResidentDuration() {
    if (!this.residentDurationInput) return

    let value = this.residentDurationInput.value;
    if (value > 0) {
      showBlock(this.moveDateDiv);
      this.syncFormWithDatesNotKnown();
    } else {
      hide(this.moveDateDiv);
      this.requireMoveDates(false);
    }
  }


  requireMoveDates(required) {
    if (this.moveInDateInput) {
      this.moveInDateRequiredSymbol.hidden = !required;
      this.moveInDateInput.required = required;
    }

    if (this.moveOutDateInput) {
      this.moveOutDateRequiredSymbol.hidden = !required;
      this.moveOutDateInput.required = required;
    }
  }

  requireResidentDuration(required) {
    this.residentDurationRequiredSymbol.hidden = !required;
    this.residentDurationInput.required = required;
  }
}
