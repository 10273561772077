import { Controller } from "@hotwired/stimulus";
import { showBlock, hide } from '../../../utils';
import { setupTooltips } from "../../../bootstrap";

// Reference Rent Summary Form controller
export default class extends Controller {

  connect() {
    this.latePaymentCount = this.element.querySelector('#reference_late_payment_count');
    this.nsfCount = this.element.querySelector('#reference_nsf_count');
    this.latePaymentWhenDiv = this.element.querySelector('#late-payments-when-div');
    this.nsfWhenDiv = this.element.querySelector('#nsf-when-div');

    this.latePaymentCount?.addEventListener("input", this.syncFormWithLatePaymentCount.bind(this));
    this.nsfCount?.addEventListener("input", this.syncFormWithNsfCount.bind(this));

    this.syncFormWithLatePaymentCount();
    this.syncFormWithNsfCount();
    setupTooltips();
  }

  latePaymentCountEventListener(e) {
    this.syncFormWithWouldRentAgain();
  }

  nsfCountEventListener(e) {
    this.syncFormWithNsfCount();
  }

  syncFormWithLatePaymentCount() {
    if (!this.latePaymentCount) return

    let value = this.latePaymentCount.value;
    if (value > 0) {
      showBlock(this.latePaymentWhenDiv);
    } else {
      hide(this.latePaymentWhenDiv);
    }
  }

  syncFormWithNsfCount() {
    if (!this.nsfCount) return

    let value = this.nsfCount.value;
    if (value > 0) {
      showBlock(this.nsfWhenDiv);
    } else {
      hide(this.nsfWhenDiv);
    }
  }
}
