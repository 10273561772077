import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // TODO: Make integers only default
    const integersOnly = this.element.dataset.integersOnly === "true" || false;
    if (integersOnly) {
      this.element.addEventListener("keydown", this.formatInputListener);
    }
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.formatInputListener);
  }

  formatInputListener(e) {
    const invalidChars = [".", "-", "e", "+", "E"];

    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }
}
