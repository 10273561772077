import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Maskito } from "@maskito/core";
import { maskitoDateOptionsGenerator } from "@maskito/kit";

export default class extends Controller {
  connect() {
    this.datepicker = flatpickr(this.element, this.flatpickrConfig());
    this.displayedInput = this.element.nextElementSibling;

    new Maskito(this.displayedInput, this.maskitoConfig());
    this.displayedInput.addEventListener("input", this.formatInputValue);
  }

  flatpickrConfig() {
    return {
      allowInput: true, // Allow users to enter input without using datepicker
      altInput: true, // Enable alternate display input
      altFormat: "m/d/Y", // Display date in more user friendly MM/DD/YYYY manner
      dateFormat: "Y-m-d", // But store input value in standard YYYY-MM-DD
      minDate: Date.parse("01/01/1900"),
    };
  }

  maskitoConfig() {
    return maskitoDateOptionsGenerator({
      mode: "mm/dd/yyyy",
      separator: "/",
    });
  }

  // Prevent letter input to prevent people from attempting to parse dates like "January 1, 1995".
  // Does not parse correctly with the different alt/date format.
  formatInputValue(e) {
    e.target.value = e.target.value.replace(/[^0-9\-./]/g, "");
  }
}
